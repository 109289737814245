<template>
  <formRegister/>
 </template>
   
 <script>
     import formRegister from '@/components/auth/formRegister' 
     
     export default {
       components: {
         formRegister
       }
     }
 </script>