<template>
  <div>
    <v-app-bar color="primary" app :class="{ 'navbar--hidden': !showNavbar }">
      <v-app-bar-nav-icon @click="sidebar" class="d-flex d-md-none" color="white" />
      <v-toolbar-title>
        <router-link to="/"> 
          <v-img
            alt="LOGO POLO"
            class="mr-2"
            contain
            style="margin-top:10px; height: 80px;"
            src="@/assets/img/logofii1.png"
            transition="scale-transition"
            width="200"
          />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <template v-if="authenticated">
        <div
          align-with-title
          class="d-none d-md-flex "
          v-for="(item, index) in itemsGuest" 
          :key="index"
          :to="item.to"
          link
        >
          <v-btn
            :to="{ path: item.path, hash: item.hash }"
            class="font-weight-bold white--text"
            text
          >
            {{ item.title }}
          </v-btn>
        </div>
      </template>

      <v-spacer></v-spacer>
      <div 
        v-if="authenticated"
        class="d-none d-md-flex"
      >
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              small
              v-bind="attrs"
              v-on="on"
            >
              {{user.user.name}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="logout()">
              <v-list-item-title >Cerrar sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div 
        v-if="!authenticated"
        class="d-none d-md-flex"
      >
      <v-card>
        <v-btn
          to="login"
          color="grey darken-4"
          class="font-weight-bold white--text"
        >
          Iniciar sesión
        </v-btn>
      </v-card>
      </div>
    </v-app-bar>
    <v-navigation-drawer 
    v-model="drawer"
    absolute
    temporary
    >
      <v-list class="mt-6">
        <v-list-item>
          <v-img
            alt="Logo Polo cientifico"
            class="shrink mr-2"
            contain
            src="@/assets/img/logofii1.png"
            transition="scale-transition"
            width="220"
          />
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <div class="px-2 mx-auto mt-10" v-if="authenticated">
        <v-btn
          block
          to="/gestion-qr"
          color="primary"
          class="font-weight-bold white--text mb-2"
        >
          Gestion Qr
        </v-btn>
        <v-btn
          block
          to="/reportes"
          color="primary"
          class="font-weight-bold white--text mb-2"
        >
          Reportes de Asistencia
        </v-btn>
        <template>
          <div class="pa-2 logoutButton">
            <v-btn block @click="logout()">
            Cerrar sesión
            </v-btn>
          </div>
        </template>       
      </div>
      <div v-if="!authenticated">
        <v-btn
          block
          to="login"
          color="grey darken-4"
          class="font-weight-bold white--text"
        >
          Iniciar sesión
        </v-btn>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
 
export default {
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated',
      user:'user',
    })
  },
  data() {
    return {
      itemsGuest: [
        { title: 'Gestión QR', path: '/gestion-qr'},
        { title: 'Listado de Asistencia', path: '/reportes'}
      ],
      loading: false,
      showNavbar: true,
      lastScrollPosition: 0,
      drawer: false,
      tab: null,
    }
  },
  methods: {
    async logout () {
      this.loading = true;
      try {
        await this.$store.dispatch('logout');
        await this.$router.push({ name: 'login' })
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    sidebar () {
      this.drawer = true
    },  
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
        return
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  },  
}
</script>

<style scoped>
  .v-application--wrap{
    min-height: 0vh!important;
  }
  .v-app-bar {
    height: 94px!important;
    padding: 10px 0 10px 10px;
    position: fixed!important;
    transform: translate3d(0, 0, 0)!important;
    transition: 0.4s all ease-out!important;
  }
  .v-app-bar.navbar--hidden {
    box-shadow: none!important;
    transform: translate3d(0, -100%, 0)!important;
  }

  .logoutButton{
    margin-top: 300px;
  }
</style>