<template>
   <v-container>
    
      <v-card elevation="3" width="800" class="ma-auto mb-16">
          <v-card-title class="text-h4 primary white--text">
              Registrar
          </v-card-title>
          <v-card-subtitle class="text primary white--text">Ingrese sus datos para ser registrado en el sistema</v-card-subtitle>
          <v-form ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="mt-3" cols="12" md="6">
                  <v-text-field 
                    v-model="user.id_card" 
                    outlined 
                    :rules="ciRules"
                    label="Cédula" 
                    type="number"
                  />
                </v-col>
                <v-col class="mt-3" cols="12" md="6">
                  <v-text-field
                    v-model="user.name" 
                    outlined 
                    :rules="nameRules"
                    label="Nombre y Apellido" 
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.email" 
                    outlined 
                    :rules="emailRules"
                    label="Correo electrónico" 
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.phone" 
                    outlined 
                    :rules="phoneRules"
                    label="Número celular"
                    type="number"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select 
                    label="Seleccione su institución" 
                    outlined
                    :items="institutions"
                    item-text="name" 
                    item-value="id"
                    v-model="user.institution_id"
                    :rules="institutionRules">
                  </v-select>
                  </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.department" 
                    outlined 
                    :rules="departamentRules" 
                    label="Departamento" 
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.role" 
                    outlined 
                    :rules="postRules" 
                    label="Cargo" 
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-file-input
                     v-model="photo"
                    label="Adjunte foto tipo carnet"
                    outlined>
                  </v-file-input>
                </v-col>
                <v-col class="mb-7" cols="12" md="4">
                  <v-checkbox
                    v-model="user.generate_qr"
                    label="¿Posee código QR?"
                    color="primary"
                    value=""
                    hide-details>
                  </v-checkbox>
                </v-col>
                <v-col class="mb-7" cols="12" md="4">
                  <v-checkbox
                    v-model="user.is_visitor"
                    label="¿Es visitante?"
                    color="primary"
                    value=""
                    hide-details>
                  </v-checkbox>
                </v-col>
              </v-row>
              <center>
                <v-btn
                  class="mb-6"
                  outlined 
                  width="200" 
                  color="primary" 
                  elevation="3" 
                  @click="validate()"
                  :loading="loading">
                  <b>Registrar</b>
                </v-btn>
              </center>
            </v-container>
          </v-form>
      </v-card>

   </v-container>
</template>

<script>
  import repository from '@/api/repository'
  import axios from 'axios'
  
  export default {
    name: 'Register',
    data() {
      return {
        loading: false,
        disabled:false,
        valid: false,
        photo: null,
        user: {
          id_card: '',
          name: '',
          email: '',
          phone: '',
          institution_id: '',
          department: '',
          role: '',
          generate_qr: true,
          is_visitor: false
        },
        institutions: [],
        ciRules: [
          v => !!v || 'La Cédula es obligatoria',
         /*  v => v.length >= 7 || 'La cédula debe ser mayor a 7 carácteres', */
        ],
        nameRules: [
          v => !!v || 'El nombre y apellido es obligatorio',
        ],
        emailRules: [
          v => !!v || 'El correo electrónico es obligatorio',
          v => /.+@.+/.test(v) || 'El correo electrónico no es válido',
        ],
        phoneRules:[
          v => !!v || 'El número de celular es obligatorio'
        ],
        institutionRules: [
          v => !!v || 'Debe seleccionar el nombre de su institución'
        ],

        departamentRules: [
          v => !!v || 'El nombre de su departamento es obligatorio'
        ],
        postRules: [
          v => !!v || 'Debe identificar su cargo'
        ],

      }
    },
    mounted(){
      this.getInstitutions()
    },
    methods: {
      saveFile (){
        let dataForm = new FormData();

        dataForm.append('photo', this.photo)
        dataForm.append('id_card', this.user.id_card)

        let config = {
          header : {
            'Content-Type' : 'multipart/form-data'
          }
        }

        axios.post('/api/saveFile', dataForm, config).then(
          async response => {
            this.showDetail(response.data.message, 'success', 'mdi-check')
            this.loading = false
            this.user = {
              id_card: '',
              name: '',
              email: '',
              phone: '',
              institution_id: '',
              department: '',
              role: '',
              generate_qr: true,
              is_visitor: false
            },
            this.photo = null;
            setTimeout(() => {
              this.$router.push('/gestion-qr')
            }, 2000);
          }
        ).catch(err => {
          this.showDetail(err.message, 'error', 'mdi-close')
        })
      },

      async savePerson() {
        try {
          repository.savePerson(this.user).then(res => {
            this.loading = true
            if(res.data && res.data.flag){
              this.saveFile()
            }else{
              this.showDetail(res.data.message, 'error', 'mdi-close-circle')
            }
          })

        } catch (error) {
          this.showDetail(error, 'error', 'mdi-close-circle')
        } finally {
          this.loading = false
          this.disabled = false
        }
      },
      async validate () {
        this.$refs.form.validate()
        if (this.$refs.form.validate()) {
          this.savePerson()
        }
      },
      showDetail(message, color, icon){
        this.$root.Alerts.show({message: message, color:color, icon: icon})
      },

      getInstitutions(){
        try {
          repository.institutions().then(res => {
            this.institutions = res.data.data
          })
        } catch (error) {
       }    
      }
    }
  }
</script>

<style>
    .v-application .text-h4{
    font-family: 'Nunito', sans-serif!important
    }

    @media only screen and (max-width: 500px){
    .login{
        width: 90% !important
    }
    }
</style>