<template>
  <v-app>
    <Alerts ref="Alerts"/>
    <v-main> 
      <Navbar/>
      <div style="margin-top: 50px;">
        <router-view/>
      </div>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/ui/Navbar.vue'
import Alerts from '@/components/ui/Alerts.vue'
import Footer from '@/components/ui/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Alerts,
    Footer
  },
  mounted () {
    let a = this.$refs;
    setTimeout(() => {
      this.$root.Alerts = a.Alerts;
    },1000)
  },
  computed: {
    
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500&display=swap');

#app {
  font-family: 'Nunito', sans-serif!important;
}

.font-20 {
  font-size: 20px;
}
</style>