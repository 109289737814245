<template>
    <div>
        <v-card elevation="3" width="600" class="my-6 ma-auto login">
            <v-card-title class="primary white--text justify-center">
                <h4 class="text-h4 text-center">Iniciar Sesión</h4>
            </v-card-title>
            
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                  <v-col class="mt-3" cols="12" md="12">
                    <v-text-field v-on:keyup.enter="validate()"
                      v-model="user.email" 
                      outlined 
                      :rules="emailRules" 
                      label="Correo Electrónico" 
                    />
                  </v-col>
        
                  <v-col cols="12" md="12">
                    <v-text-field v-on:keyup.enter="validate()"
                      v-model="user.password" 
                      outlined 
                      :rules="passwordRules" 
                      label="Contraseña" 
                      type="password"
                    />
                  </v-col>
                </v-row>
    
                <center>
                  <v-btn
                    class="mb-6"
                    outlined 
                    width="200" 
                    color="primary" 
                    elevation="3" 
                    @click="validate()"
                    :disabled="loading"
                  >
                    <b v-if="loading">Iniciando...</b>
                    <b v-else>Iniciar sesión</b>
                  </v-btn>
                </center>
              </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
    export default {
      name: 'Login',
      data() {
        return {
          loading: false,
          valid: false,
          user: {
            email: "",
            password: ""
          },
          emailRules: [
            v => !!v || 'El correo electrónico es obligatorio',
            v => /.+@.+/.test(v) || 'El correo electrónico no es válido',
          ],
          passwordRules: [
            v => !!v || 'La Contraseña es obligatoria',
            v => v.length >= 6 || 'La contraseña debe ser mayor a 6 caracteres',
          ],
        }
      },
    
      methods: {

        async login() {
          this.loading = true;

          try {
            let res = await this.$store.dispatch('login', this.user);

            if(res && res.error){
              this.showDetail(res.data.message, 'mdi-close-circle')
            }else{
              await this.$router.push(this.$route.query.redirect || '/gestion-qr');
            }

          } catch (error) {
            this.showDetail(error, 'mdi-close-circle')
          } finally {
            this.loading = false;
          }
        },
        async validate () {
          this.$refs.form.validate()
          if (this.$refs.form.validate()) {
            this.login()
          }
        },
        showDetail(message, icon){
          this.$root.Alerts.show({message: message, color:'secondary', icon: icon});
        },
      }
    }
</script>

<style>
    .v-application .text-h4{
    font-family: 'Nunito', sans-serif!important;
    }

    @media only screen and (max-width: 500px){
    .login{
        width: 90% !important;
    }
    }
</style>