<template>
    <div>
      <dailyReports/>
    </div>
</template>
  
<script>
  import dailyReports from '@/components/dailyReports/index'
  
  export default {
    name: 'dailyReportsView',
    components: {
      dailyReports
    },
  }
</script>