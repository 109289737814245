import manageQr from '@/views/manageQr/index'
import dailyReports from '@/views/dailyReports/index'
import Login from '@/views/auth/login'
import Register from '@/views/auth/register'
import middleware from './middleware';

export default [{
        path: '/',
        redirect: '/login',
        beforeEnter: middleware.guest,

    },

    {
        path: '/gestion-qr',
        name: 'gestion-qr',
        component: manageQr,
        beforeEnter: middleware.user,
    },
    {
        path: '/reportes',
        name: 'reportes',
        component: dailyReports,
        beforeEnter: middleware.user,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: middleware.guest,

    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        //beforeEnter: middleware.guest,

    }

]