<template>
    <v-snackbar 
        width="500"
        top 
        elevation="3" 
        :color="color"
        v-model="showSnackbar"
    >
        <v-row>
            <v-col v-if="desktop" cols="1">
                <v-icon 
                    dark 
                    size="42" 
                >
                    {{ icon }}
                </v-icon>
            </v-col>
            <v-col cols="11">
                <h3 class="ml-5">
                    {{ message }}
                </h3>
            </v-col>
        </v-row>
        <template v-slot:action="{ attrs }">
            <v-btn 
                color="white" 
                text 
                v-bind="attrs" 
                @click="showSnackbar = false"
            >
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default{
        data(){
            return{
                desktop:true,
                showSnackbar: false,
                message: '',
                color: 'success',
                icon: 'mdi-check',
                timer: 3000
            }
        },
        mounted(){
            this.desktop=this.$device
        },
        methods:{
            show(data) {
                this.message = data.message || 'missing "message".'
                this.color = data.color || 'success'
                this.timer = data.timer || 3000
                this.icon = data.icon || 'mdi-check'
                this.showSnackbar = true
            }
        }

    }
</script>