<template>
  <v-container>
    <v-card class="mb-16">
      <v-card-title>
        
        <span>Reporte de Asistencia</span>
        
        <v-spacer></v-spacer>
        
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details>
        </v-text-field>
      </v-card-title>

      <div class="d-flex">
        <v-chip-group
          mandatory
          active-class="primary--text"
          class="ml-5"
        >
          <v-chip @click="changeType('daily')">Diario</v-chip>
          <v-chip @click="changeType('monthly')">Mensual</v-chip>
        </v-chip-group>
        <div class="pe-4 ml-auto" outlined tile> 
            <v-btn
              elevation="3"
              color="red"
              @click="downloadReport('daily')"
            >
              <v-icon
                color="white">
                mdi-file-pdf-box
              </v-icon>
            </v-btn>
        </div>
      </div>
      
      <v-data-table
        :headers="headers"
        :items="attendanceList"
        :search="search"
        :header-props="headerProps"
        :footer-props="footerProps"
        :loading="loadingData"
        loading-text="Cargando... Por favor, espere.">

        <template v-slot:item.actions="{item}">
          <v-tooltip left color="red">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              elevation="3"
              fab
              small
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              :loading="loadingData" 
              color="red"
              :disabled="item.departure_date ? disabledButton=true : disabledButton=false"
              @click="showDialog(item.id)"
              >
                <v-icon
                  color="white">
                  mdi-account-arrow-right
                </v-icon>
              </v-btn>
            </template>
            <span>Firmar Salida</span>
          </v-tooltip>
        </template>
        
        <template v-slot:no-data>
          <h3 class="my-4 mx-10">No hay datos disponibles</h3>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
      <v-card>
        <v-card-title class="text-h6">
          ¿Desea firmar la salida?
        </v-card-title>
        <v-card-text>Presione "Aceptar", si desea firmar la salida.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            :disabled="disabled"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :disabled="disabled"
            @click="updateAttendance()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
  import repository from "@/api/repository";
  
  export default {
    data () {
      return {
        headerProps: {
          sortByText: "Ordenar por"
        },
        type:"daily",
        footerProps: {
          showFirstLastPage: true,
          itemsPerPageText: 'Registros por página',
          pageText: '{0}-{1} de {2}',
          itemsPerPageAllText: 'Todos'
        },
        search: '',
        loadingData: false,
        attendanceList: [],
        dialog: false,
        selectedAttendance: '',
        disabled: false,
        disabledButton:true
      }
    },

    async mounted() {
      this.getAttendance("daily");
    },

    computed: {

      headers () {
        return [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id'
          },
          {
            text: 'Nombre',
            align: 'start',
            value: 'person.name'
          },
          { 
            text: 'Cédula', 
            align: 'center', 
            value: 'person.id_card' 
          },
          { 
            text: 'Institución', 
            align: 'center', 
            value: 'person.institution.name' 
          },
          { 
            text: 'Email', 
            align: 'center', 
            value: 'person.email' 
          },
          { 
            text: 'Teléfono', 
            align: 'center', 
            value: 'person.phone' 
          },
          { 
            text: 'Entrada', 
            align: 'center', 
            value: 'admission_date' 
          },
          { 
            text: 'Salida', 
            align: 'center', 
            value: 'departure_date' 
          },
          { 
            text: 'Acciones', 
            align: 'center', 
            value: 'actions' 
          }
        ]
      }
    },

    methods:{
      getAttendance(){
        this.loadingData = true
        try {
          repository.getAttendances(this.type).then(res => {
            console.log("puta",res);
            this.attendanceList = res.data.data
            this.loadingData = false;
          });
        } catch (error) {
          this.error = error;
          this.loadingData = false;
        }
      },
      changeType(param) {

        this.type = param

        this.getAttendance();
      },
      downloadReport(){
        this.loadingData = true
        try {
          repository.downloadReport(this.type).then(res => {
            let base64String = res.data.data;
  
            const downloadLink = document.createElement("a");
            
            downloadLink.href = base64String;
            
            downloadLink.download = 'Reporte de asistencia.pdf';
            
            downloadLink.click();

            this.loadingData = false;
          });
        } catch (error) {
          this.error = error;
          this.loadingData = false;
        }
      },

      showDialog(id){
        this.dialog = true;
        this.selectedAttendance = id
      },

      updateAttendance(){
        this.disabled=true
        try{
          repository.updateAttendance(this.selectedAttendance).then(res=>{
            if(res.data.flag){
              this.showDetail(res.data.message, 'success', 'mdi-check-bold')
            } 
            this.dialog=false;
            this.disabled=false;
            this.getAttendance()
          })
        } catch (err){
          this.showDetail(res.data.message, 'red', 'mdi-close-thick')
        }
      },

      showDetail(message, color, icon){
            this.$root.Alerts.show({message: message, color: color, icon: icon});
        }
    }
  }
</script>
<style>
  #app {
    font-family: 'Nunito', sans-serif!important;
  }
</style>