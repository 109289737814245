import Vue from 'vue'
import Vuex from 'vuex'
import repository from "../api/repository";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: {
            state: {
                user: sessionStorage.user ? JSON.parse(sessionStorage.getItem('user')) : null,
            },
            getters: {
                user: state => state.user,
                authenticated: state => state.user !== null,
            },
            mutations: {
                SET_USER(state, user) {
                    state.user = user;
                }
            },
            actions: {
                async login({ commit }, user) {
                    const { data } = await repository.login(user);
                    if (data.user && data.token) {
                        commit('SET_USER', data);
                        sessionStorage.user = JSON.stringify(data);
                        sessionStorage.token = data.token;
                    } else {
                        return {
                            error: true,
                            data
                        };
                    }
                },
                async logout({ commit }) {
                    await repository.logout();
                    commit('SET_USER', null);
                    sessionStorage.removeItem('user');
                }
            }
        }
    }
});