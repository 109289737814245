import api from "./api";

const API_URL = process.env.VUE_APP_API_URL || 'http://10.10.10.103';

export default {

    login(params) {
        return api.post(`${API_URL}/login`, params);
    },

    logout() {
        return api.get(`${API_URL}/logout`);
    },

    getAttendances(param) {
        let route = param ? `${API_URL}/api/attendance?type=${param}` : `${API_URL}/api/attendances?type=daily`

        return api.get(route);
    },

    downloadReport(param) {
        let route = param ? `${API_URL}/api/attendance/downloadReport?type=${param}` : `${API_URL}/api/attendances/downloadReport?type=daily`

        return api.get(route);
    },

    sendIdentification(param) {
        return api.get(`${API_URL}/api/person/getByCedula/${param}`);

    },

    savePerson(param) {
        return api.post(`${API_URL}/api/register`, param);
    },

    saveFile() {
        return api.post(`${API_URL}/api/saveFile`);
    },

    institutions() {
        return api.get(`${API_URL}/api/institution`);
    },

    createAttendance(param) {
        let data = {
            person_id: param
        }
        return api.post(`${API_URL}/api/attendance`, data);
    },

    updateAttendance(id) {
        return api.put(`${API_URL}/api/attendance/${id}`);
    }

};