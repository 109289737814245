<template>
    <div>
      
      <manageQr/>

    </div>
  </template>
  
  <script>
  import manageQr from '@/components/manageQr/index'
  export default {
    name: 'manageQrView',
    components: {
      manageQr
    },
  }
  </script>