<template>
    <v-container>
        <v-row justify="space-around">
            <v-col cols="12" md="4">
                <v-card
                    class="mx-auto"
                    elevation="3"
                >
                    <v-card-title>
                        
                        <p class="error">{{ error }}</p>
                        <p class="decode-result"><b>Lector QR: {{ result }}</b></p>
                        <qrcode-stream @init="onInit"  @decode="sendIdentification" :track="paintBoundingBox"></qrcode-stream>
                            
                    </v-card-title>
                    
                </v-card>

                    <div class="d-flex my-6">
                        <v-divider width="100" class="borderQr mt-3 mx-2 primary"> </v-divider>
                        <b>O</b>
                        <v-divider class="borderQr mt-3 mx-2 primary"> </v-divider>
                    </div>
                <div class="text-center mt-6" >
                    <v-btn class="primary mx-auto" style="width: 90%;" to="register">Registrar persona</v-btn>
                </div>
                
            </v-col>
            <v-col cols="12" md="4">
                <v-card width="800" elevation="3"  class="mx-auto" v-if="person">
                    <v-card-title class="text-h4">
                        <b>Datos QR</b>
                    </v-card-title>
                    <v-card-subtitle class=""><b>Se mostrarán los datos de la persona asociados al QR.</b></v-card-subtitle>
                    <v-form ref="form">
                        <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                        <v-container>
                            <v-row v-if="person">
                                <v-col cols="12">
                                    <center>
                                        <v-avatar  v-if="person.photo_string == '' || person.photo_string == null"
                                            width="100" 
                                            height="100" 
                                            color="grey darken-3"
                                        >
                                                <v-icon x-large dark>
                                                    mdi-account-circle
                                                </v-icon>
                                        </v-avatar>
                                        <v-avatar 
                                            v-else 
                                            width="150" 
                                            height="150"
                                        >
                                            <img
                                                :src="person.photo_string"
                                                alt="Foto"
                                            >
                                        </v-avatar>
                                    </center>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field 
                                        v-model="person.id_card" 
                                        outlined
                                        label="Cédula" 
                                        type="number"
                                        disabled
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="person.name" 
                                        outlined
                                        label="Nombre y Apellido" 
                                        disabled
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="person.email" 
                                        outlined
                                        label="Email"
                                        disabled 
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="person.phone" 
                                        outlined
                                        label="Teléfono"
                                        type="number"
                                        disabled
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-text-field 
                                        v-model="person.institution.name" 
                                        label="Institución" 
                                        outlined
                                        type="text"
                                        disabled
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="text-center">
                                    <v-btn
                                        class="mb-8"
                                        large
                                        color="success"
                                        :disabled="disabled"
                                        @click="sendAttendance(person.id)">

                                        <b v-if="loading">Confirmando...</b>
                                        <b v-else>Confirmar Asistencia</b>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import  {QrcodeStream}  from 'vue-qrcode-reader';
import repository from "@/api/repository";

export default {
    components: {
        QrcodeStream,
    },
    data () {
        return {
            result: '',
            error: '',
            loading: false,
            disabled: true,
            person: {
                id_card: '',
                name: '',
                email: '',
                phone: '',
                institution: '',
                photo_string: ''
            },
        }
    },
    methods: {
        async onInit(promise) {
        try {
            const { capabilities } = await promise
            // successfully initialized
            } catch (error) {
            if (error.name === 'NotAllowedError') {
                // user denied camera access permisson
                alert("No se ha concedido permiso a la camara")
            } else if (error.name === 'NotFoundError') {
                // no suitable camera device installed
                alert("No se ha conseguido la camara")

            } else if (error.name === 'NotSupportedError') {
                // page is not served over HTTPS (or localhost)
                alert("No se ha permitido permiso al local")

            } else if (error.name === 'NotReadableError') {
                // maybe camera is already in use
                alert("Error con la camara")

            } else if (error.name === 'OverconstrainedError') {
                // did you requested the front camera although there is none?
                alert("Se ha solicitado la camara frontal y no tiene")

            } else if (error.name === 'StreamApiNotSupportedError') {
                alert("El navegadir no soporta camara")
            }
            } finally {
            }
        },

        sendIdentification(dataString){
            const regex = /(\d+)/g;

            let dataUser = dataString;
            let cedulaArray = dataUser.match(regex)

            let cedula = cedulaArray.toString().replace(/,/g, "")

            this.loadingData = true
            try {
            repository.sendIdentification(cedula).then(res => {
                if(res.data.flag){
                this.person = res.data.data
                this.disabled = false
                }else {
                    this.showDetail(res.data.message, 'error', 'mdi-close-thick')

                    this.person = {
                        id_card: '',
                        name: '',
                        email: '',
                        phone: '',
                        institution: '',
                        photo_string: ''
                    }
                    setTimeout(() => {
                        this.$router.go(0);
                    }, 2000);
                }
            });
            } catch (error) {
                this.person = {
                    id_card: '',
                    name: '',
                    email: '',
                    phone: '',
                    institution: '',
                    photo_string: ''
                }
            this.error = error;
            this.loadingData = false;
            setTimeout(() => {
                this.$router.go(0);
            }, 2000);
            }
        },

        sendAttendance(id){
            this.loading = true
            this.disabled= true
            try {
                repository.createAttendance(id).then(res=>{
                    if(res.data.flag){
                        this.showDetail('Se ha confirmado la asistencia exitosamente.', 'success', 'mdi-check-bold')
                        this.loading = false
                        this.disabled = false
                        
                        this.person = {
                            id_card: '',
                            name: '',
                            email: '',
                            phone: '',
                            institution: '',
                            photo_string: ''
                        }
                        this.disabled = true

                        setTimeout(() => {
                            this.$router.go(0);
                        }, 2000);
                    } else {
                        this.loading = false
                        this.showDetail(res.data.message, 'error', 'mdi-check-bold')

                        this.person = {
                            id_card: '',
                            name: '',
                            email: '',
                            phone: '',
                            institution: '',
                            photo_string: ''
                        }
                        this.disabled = true;
                        setTimeout(() => {
                            this.$router.go(0);
                        }, 2000);
                    }
                })
            } catch (err) {
            }
        },

        paintBoundingBox (detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
            const { boundingBox: { x, y, width, height } } = detectedCode

            ctx.lineWidth = 2
            ctx.strokeStyle = '#007bff'
            ctx.strokeRect(x, y, width, height)
        }
        },

        showDetail(message, color, icon){
            this.$root.Alerts.show({message: message, color: color, icon: icon});
        }
    }
}
</script>

<style>

    .borderQr{
        margin: 0 20px;
    }

</style>
