<template>
    <v-footer
      dark
      padless
      class="footerQr"
    >
      <v-card
        flat
        tile
        width="100%"
        class="primary white--text text-center"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
  
        <v-card-text class="white--text pt-0">
            <v-container>
                <v-row>
                    <v-col cols="12" md="4">
                        <h5 class="text-h5">Contáctanos</h5>
                        <v-card-text class="pt-5">
                            <p>(+58)(0212)-5358998</p>
                            <p>atencion.cliente.pscfii@gmail.com</p> 
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="4">
                        <h5 class="text-h5">Nosotros</h5>
                        <v-card-text class="pt-5">
                            <p>Polo Científico Tecnológico</p> 
                            <p>RIF G-20004650-3</p> 
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="4">
                        <h5 class="text-h5">Ubícanos</h5>
                        <v-card-text class="pt-5">
                            <p>Carretera Nacional Hoyo de la Puerta Urb. Monte Elena II, Altos de Sartenejas, Estado Miranda-Venezuela.</p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <v-avatar size="36px"> <img alt="Avatar" src="@/assets/img/iconofii2.png"> </v-avatar>
        </v-card-text>
      </v-card>
    </v-footer>
  </template>

<script>
    export default {
        data: () => ({
            icons: [ 'mdi-facebook', 'mdi-twitter', 'mdi-instagram' ],
        }),
    }
</script>

<style>

    .v-application .text-h5{
        font-family: 'Nunito', sans-serif!important;
    }
    
    @media screen and (min-width: 1024px) {
            .footerQr{
                height:50px;
                line-height:50px;
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
            }
    }

    @media screen and (min-width: 768px) {
            .footerQr{
                height:50px;
                line-height:50px;
                position:absolute;
                bottom:0;
                left:0;
                width:100%;
            }
    }
</style>