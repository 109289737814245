<template>
   <div>
       <formLogin/>
   </div> 
</template>
  
<script>
    import formLogin from '@/components/auth/formLogin' 
    
    export default {
      components: {
        formLogin
      }
    }
</script>